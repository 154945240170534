import Query from "../Query";

import KONTAKT_QUERY from "../../queries/kontakt";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Footer() {
  let year = new Date().getFullYear();

  return (
    <Query query={KONTAKT_QUERY}>
      {({ data: { contact } }) => {
        const kontakt = contact.data.attributes;
        return (
          <footer className="footer layout layout__min layout__bg layout__bg_primary">
            <Container fluid>
              <Row>
                <Col xs={12} xxl={6} className="mb-3 mb-xxl-0">
                  <h2
                    data-aos="fade-up"
                    className="layout_title layout_title__light text-center text-xxl-start us_not"
                  >
                    FAHRSCHULE{" "}
                  </h2>
                  <h2
                    data-aos="fade-up"
                    className="text-center text-xxl-start us_not"
                  >
                    BERGEDORF
                  </h2>
                </Col>
                <Col
                  xs={12}
                  xxl={4}
                  className="offset-xxl-1 text-center text-xxl-start mb-4 mb-xxl-0"
                >
                  <a
                    data-aos="fade-up"
                    href="https://goo.gl/maps/7bJGEYVU7rwA4w8K7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={
                        process.env.REACT_APP_BACKEND_URL +
                        kontakt.Map.data.attributes.url
                      }
                      alt={kontakt.address}
                      className="footer_map"
                    />
                  </a>
                </Col>
                <span className="layout_mt"></span>
                <Col
                  xs={12}
                  xxl={6}
                  className="order-1 order-xxl-0 layout_mt mt-xxl-0"
                >
                  {kontakt.telephone !== null ? (
                    <p
                      data-aos="fade-up"
                      className="footer_text text-center text-xxl-start"
                    >
                      Tel:{" "}
                      <a href={"tel:" + kontakt.telephone}>
                        {kontakt.telephone}
                      </a>
                    </p>
                  ) : (
                    ""
                  )}
                  {kontakt.mobile !== null ? (
                    <p
                      data-aos="fade-up"
                      className="footer_text text-center text-xxl-start"
                    >
                      Mobil:{" "}
                      <a href={"tel:" + kontakt.mobile}>{kontakt.mobile}</a>
                    </p>
                  ) : (
                    ""
                  )}
                  {kontakt.email !== null ? (
                    <p
                      data-aos="fade-up"
                      className="footer_text text-center text-xxl-start"
                    >
                      Email:{" "}
                      <a href={"mailto:" + kontakt.email}>{kontakt.email}</a>
                    </p>
                  ) : (
                    ""
                  )}
                </Col>
                <Col
                  xs={12}
                  xxl={4}
                  className="offset-xxl-1 pt-2 pt-xxl-0 mb-4 mb-xxl-0"
                >
                  <p
                    data-aos="fade-up"
                    className="footer_text text-center text-xxl-start"
                  >
                    Bürozeiten:{" "}
                    <span
                      className="color_light"
                      dangerouslySetInnerHTML={{
                        __html: kontakt.helptime,
                      }}
                    ></span>
                  </p>
                  <p
                    data-aos="fade-up"
                    className="footer_text text-center text-xxl-start"
                  >
                    <a
                      href="https://goo.gl/maps/7bJGEYVU7rwA4w8K7"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer_mw d-inline-block"
                    >
                      {kontakt.address}
                    </a>
                  </p>
                </Col>
              </Row>
              <Row className="layout_mt">
                <Col xs={12} xxl={2}>
                  <p
                    data-aos="fade-up"
                    className="footer_text footer_text__small text-center text-xxl-start mb-3 mb-xxl-0"
                  >
                    <a href="/datenschutz">Datenschutz</a>
                  </p>
                </Col>
                <Col xs={12} xxl={2}>
                  <p
                    data-aos="fade-up"
                    className="footer_text footer_text__small text-center text-xxl-start mb-3 mb-xxl-0"
                  >
                    <a href="/impressum">Impressum</a>
                  </p>
                </Col>
                <Col xs={12} xxl={2}>
                  <p
                    data-aos="fade-up"
                    className="footer_text footer_text__small text-center text-xxl-start mb-3 mb-xxl-0"
                  >
                    <a href="/agb">AGB</a>
                  </p>
                </Col>
                <Col
                  xs={12}
                  xxl={4}
                  className="layout_mt mt-xxl-0 offset-xxl-1"
                >
                  <p
                    data-aos="fade-up"
                    className="footer_text footer_text__small layout_text text-center text-xxl-start"
                  >
                    made by{" "}
                    <a
                      href="https://otruta.agency/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Otruta.agency.
                    </a>{" "}
                    {year}
                  </p>
                </Col>
              </Row>
            </Container>
          </footer>
        );
      }}
    </Query>
  );
}

export default Footer;
