import React from "react";
import AOS from "aos";

import { Routes, Route } from "react-router-dom";

import MyNav from "../../components/Nav";
import Footer from "../../components/Footer";

import Home from "../Home";
import Ausbildung from "../Ausbildung";
import Category from "../Category";
import About from "../Ue-ber-uns";
import Intensivkurs from "../Intensivkurs";
import Kontakt from "../Kontakt";
import Impressum from "../Impressum";
import Cookie from "../../components/Content/cookie";
import Policy from "../Policy";
import UpNav from "../../components/Nav/up";
import Preise from "../Preise";
import WhatsUp from "../../components/Content/whatsapp";
import NewElements from "../NewElemnts";
import AGB from "../AGB";

function App() {
  AOS.init({
    duration: 700,
    easing: "ease-in-shine",
    offset: -50,
    once: true,
  });
  AOS.refresh();
  return (
    <>
      <div className="App">
        <Cookie></Cookie>
        <UpNav />
        <MyNav />
        <div className="min-height">
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/fuehrerscheine/" element={<Ausbildung />} exact />
            <Route path="/fuehrerscheine/:slug" element={<Category />} exact />
            <Route path="/:slug" element={<Category />} exact />
            <Route path="/team/" element={<About />} exact />
            <Route path="/preise/" element={<Preise />} exact />
            <Route path="/intensivkurs/" element={<Intensivkurs />} exact />
            <Route path="/kontakt/" element={<Kontakt />} exact />
            <Route path="/impressum/" element={<Impressum />} exact />
            <Route path="/datenschutz/" element={<Policy />} exact />
            <Route path="/agb/" element={<AGB />} exact />
            <Route path="/test/" element={<NewElements />} exact />
          </Routes>
        </div>
        <WhatsUp />
        {window.location.pathname !== "/kontakt" ? <Footer /> : ""}
      </div>
    </>
  );
}

export default App;
