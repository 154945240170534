import Container from "react-bootstrap/Container";

import Query from "../../components/Query";
import IMPRESSUM_QUERY from "../../queries/impressum";

import ReactMarkdown from "react-markdown";

const Impressum = () => {
  return (
    <Query query={IMPRESSUM_QUERY}>
      {({ data: { imprint } }) => {
        const elem = imprint.data.attributes;
        return (
          <>
            <main className="layout layout__color_primary">
              <Container fluid>
                <h1 data-aos="fade-up" className="layout_mb">
                  {elem.title}
                </h1>
                <div className="layout_text layout_text__special">
                  <ReactMarkdown children={elem.content} />
                </div>
              </Container>
            </main>
          </>
        );
      }}
    </Query>
  );
};

export default Impressum;
