import gql from "graphql-tag";

const TERMS_QUERY = gql`
  query Terms {
    terms {
      data {
        attributes {
          title
          content
        }
      }
    }
  }
`;

export default TERMS_QUERY;
